<template>
  <li role="presentation">
    <component
      :is="tag"
      v-bind="$attrs"
      :class="['zdropdown-item', linkClasses, { active }]"
    >
      <slot />
    </component>
  </li>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<{
  button?: boolean
  active?: boolean
  linkClasses?: string | string[]
}>(), {
  button: false,
  active: false,
  linkClasses: '',
})

const tag = computed(() => props.button ? 'button' : NuxtLink)
</script>
