<template>
  <div
    class="zdropdown"
  >
    <a
      v-if="nav"
      ref="dropdownToggleEl"
      class="znav-link zdropdown-toggle"
      @click="toggleShow"
    >
      <slot name="button-content" />
    </a>
    <ZButton
      v-else
      ref="dropdownToggleEl"
      :variant="variant"
      class="zdropdown-toggle zdropdown-toggle-caret"
      @click="toggleShow"
    >
      <slot name="button-content" />
    </ZButton>
    <ul
      v-if="showDropdown"
      ref="dropdownMenuEl"
      tabindex="-1"
      class="zdropdown-menu"
      :style="floatingStyles"
      @click.stop="hide"
    >
      <slot />
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useFloating, autoUpdate, type Placement, shift } from '@floating-ui/vue'
import { onClickOutside } from '@vueuse/core'
import type { Nullable } from '~/types'
import type { ButtonVariant } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  variant?: ButtonVariant
  nav?: boolean
  placement?: Placement
}>(), {
  variant: 'primary',
  nav: false,
  placement: 'bottom-end',
})

const emit = defineEmits<{
  open: []
}>()

const dropdownToggleEl = ref<Nullable<HTMLAnchorElement | HTMLButtonElement>>(null)
const dropdownMenuEl = ref<Nullable<HTMLUListElement>>(null)
const { floatingStyles } = useFloating(dropdownToggleEl, dropdownMenuEl, {
  placement: props.placement,
  whileElementsMounted: autoUpdate,
  middleware: [
    shift(),
  ],
})

onClickOutside(
  dropdownMenuEl,
  () => {
    hide()
  },
  { ignore: [dropdownToggleEl] },
)

const showDropdown = ref(false)

function toggleShow() {
  if (showDropdown.value) {
    hide()
  }
  else {
    emit('open')
    show()
  }
}

function show() {
  showDropdown.value = true
}

function hide() {
  showDropdown.value = false
}
</script>
